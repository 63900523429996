import { gsap, Power2 } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

export default class ScrollAnimations {
  constructor(el) {
    this.elScrollContainer = el;
    this.elScrollCards = this.elScrollContainer.querySelectorAll('.js-scroll-card:not(.js-animated)');
    this.elScrollLine = this.elScrollContainer.querySelector('.js-scroll-line');

    console.log(this.elScrollCards);
    // Breakpoint variable.
    this.mqMobile = window.matchMedia('(max-width: 767px)');

    gsap.defaults({ease: 'power2'});

    if (this.elScrollCards.length) {
      this.animateCards();
    }

    if (this.elScrollLine) {
      this.animateLine();
    }

    if (this.elScrollContainer.classList.contains('js-fade-in')) {
      this.fadeIn();
    }

    if (this.elScrollContainer.classList.contains('js-slide-from-right')) {
      this.slideFromRight();
    }
  }

  slideFromRight() {
    gsap.to(this.elScrollContainer, {
      scrollTrigger: this.elScrollContainer,
      duration: 1,
      x: 0,
      opacity: 1,
      ease: 'Power2.out',
    });
  }

  fadeIn() {
    gsap.set(this.elScrollLine, {
      y: 100,
    });

    gsap.to(this.elScrollContainer, {
      scrollTrigger: this.elScrollContainer,
      duration: 1,
      y: 0,
      opacity: 1,
      ease: 'Power2.out',
    });
  }

  animateLine() {
    let xOffset = -200;
    let xPos = -40;

    if (this.mqMobile.matches) {
      xOffset = -100;
      xPos = 0;
    }

    gsap.set(this.elScrollLine, {
      x: xOffset,
    });

    gsap.to(this.elScrollLine, {
      scrollTrigger: this.elScrollLine,
      duration: 1,
      x: xPos,
      opacity: 1,
      ease: 'back',
    });
  }

  animateCards() {
    gsap.set('.js-scroll-card:not(.js-animated)', {
      opacity: 0,
      y: 75,
      scale: 0.9,
    });

    ScrollTrigger.batch('.js-scroll-card:not(.js-animated)', {
      onEnter: batch => {
        batch.forEach((card, index) => {
          gsap.to(card, {
            opacity: 1,
            y: 0,
            scale: 1,
            stagger: 0.20,
            delay: index * 0.20,
            onComplete() {
              console.log(card);
              card.classList.add('js-animated');
            }
          })
        })
      },
      once: true,
      ease: 'out',
      start: 'top 90%'
    });
  }

  static init(selector) {
    [...document.querySelectorAll(selector)].forEach(el => new this(el));
  }
}
