import Masonry from 'masonry-layout';
import GLightbox from 'glightbox';

export default class Gallery {
  constructor(el) {
    this.elGallery = el;
    this.lightbox = null;
    this.msnry = null;

    this.initLightBox();
    this.initMasonry();
  }

  initLightBox() {
    this.lightbox = new GLightbox({
      selector: '.glightbox',
      touchNavigation: true,
      loop: true,
      autoplayVideos: true,
      plyr: {
        config: {
          volume: 1
        }
      }
    });
  }

  initMasonry() {
    this.msnry = new Masonry(this.elGallery, {
      itemSelector: '.gallery__item',
      columnWidth: '.gallery__sizer',
      gutter: '.gutter__sizer',
    });
  }

  static init(selector) {
    [...document.querySelectorAll(selector)].forEach(el => new this(el));
  }
}
