export default class Header {
  constructor(el) {
    this.elHeader = el;
    this.elHeaderHamburger = this.elHeader.querySelector('.js-header-hamburger');
    this.elNavigation = document.querySelector('.js-navigation');

    // Resize event for address bar issue on mobile.
    const appHeight = () => {
      const doc = document.documentElement;

      // Substract 60px for the sticky header room.
      doc.style.setProperty('--app-height', `${window.innerHeight}px`);
      doc.style.setProperty('--hero-height', `${window.innerHeight - 80}px`);
    };
    window.addEventListener('resize', appHeight);
    appHeight();
    this.initEvents();
  }

  initEvents() {
    this.elHeaderHamburger.addEventListener('click', () => {
      this.toggleNavigation();
    });
  }

  toggleNavigation() {
    if (this.elNavigation.classList.contains('js-open')) {
      this.elNavigation.classList.remove('js-open');
      this.elHeaderHamburger.classList.remove('js-active');
    } else {
      this.elNavigation.classList.add('js-open');
      this.elHeaderHamburger.classList.add('js-active');
    }
  }

  static init(selector) {
    [...document.querySelectorAll(selector)].forEach(el => new this(el));
  }
}
