import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { Header, Hero, Gallery, ScrollAnimations, CookieMessage } from './components';
gsap.registerPlugin(ScrollTrigger);

class App {
  constructor() {
    Header.init('.js-header');
    Hero.init('.js-hero');

    window.addEventListener('load', () => {
      Gallery.init('.js-gallery');
      ScrollAnimations.init('.js-scroll-container');
      CookieMessage.init('.js-cookie-message');

      const observer = new MutationObserver((mutationList) => {
        this.checkMutations(mutationList);
      });

      observer.observe(document.querySelector("#projects-list"), { subtree: false, childList: true });
    });
  }

  checkMutations(mutationList) {
    ScrollAnimations.init('.js-scroll-container');

    /*
    [...mutationList].forEach((mutation) => {
      mutation.addedNodes.forEach((addedNode) => {
        console.log(addedNode);
        if (addedNode.classList.contains('js-scroll-card')) {
          console.log(addedNode);
        }
      });
    });
     */
  }
}

document.addEventListener('DOMContentLoaded', () => {
  new App();
});
