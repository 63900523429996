import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default class Hero {
  constructor(el) {
    this.elHero = el;
    this.elHeroParallax = this.elHero.querySelector('.js-hero-parallax');

    this.initScrollTrigger();
  }

  initScrollTrigger() {
    const tl = gsap.timeline({scrollTrigger:{
        trigger: this.elHero,
        scrub: true,
        pinSpacing: false,
        pin:true,
        start:"top top+=80px",
        end:"+=100%",
        // markers:true
      }});

    if (this.elHeroParallax) {
      tl.to(this.elHeroParallax, {
        y: -100,
        ease: 'none'
      });
    }
  }

  static init(selector) {
    [...document.querySelectorAll(selector)].forEach(el => new this(el));
  }
}
